import * as z from 'zod';

export type Options = {
  accessToken: string;
  version?: string;
};

// GET /3.0/advertisements/user
export const AdvertisementUserSchema = z.object({
  universityID: z.number(),
  graduationYear: z.number(),
});

export const getBannerSchema = z.object({
  top: z.object({
    adType: z.string().optional(),
    adId: z.number().optional(),
    imageUrl: z.string().optional(),
    linkUrl: z.string().optional(),
  }),
  bottom: z.object({
    adType: z.string().optional(),
    adId: z.number().optional(),
    imageUrl: z.string().optional(),
    linkUrl: z.string().optional(),
  }),
});

export const getBannerZSchema = z
  .object({
    id: z.number().optional(),
    imageURL: z.string().optional(),
    linkURL: z.string().optional(),
  })
  .or(z.string());

export const getJackBannerSchema = z.object({
  mobileImageURL: z.string(),
  mobileLinkURL: z.string(),
});

export const getEngineerBannerSchema = z.array(
  z.object({
    id: z.number(),
    imageURL: z.string(),
    link: z.string(),
  }),
);

// GET /3.0/advertisements/mypage-jack-banner-setting
export const AdvertisementMypageJackBannerSettingSchema = z.object({
  isActivated: z.boolean(),
});

export const communityTopicsHeadlineSchema = z.object({
  topics: z.array(
    z.object({
      id: z.number(),
      title: z.string(),
      body: z.string(),
      nickname: z.string(),
      created_at: z.string(), // "2023-03-20 16:35:18 +0900 JST"
      last_posted_at: z.string(), // "2023-03-20 16:35:18 +0900 JST"
    }),
  ),
});
